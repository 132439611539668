// Generated by Framer (9d598a4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["Nu0FrfrIq", "owcr8bAQ2", "rNYhabyFZ", "AzpNTi8mb", "dEOTIs8oZ", "gwwCWmGR_"];

const serializationHash = "framer-v45y8"

const variantClassNames = {AzpNTi8mb: "framer-v-1bxr2i4", dEOTIs8oZ: "framer-v-1wzycri", gwwCWmGR_: "framer-v-v9fcle", Nu0FrfrIq: "framer-v-tdo1hm", owcr8bAQ2: "framer-v-12wfykl", rNYhabyFZ: "framer-v-bgfawe"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "Nu0FrfrIq", "Variant 2": "owcr8bAQ2", "Variant 3": "rNYhabyFZ", "Variant 4": "AzpNTi8mb", "Variant 5": "dEOTIs8oZ", "Variant 6": "gwwCWmGR_"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Nu0FrfrIq"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Nu0FrfrIq", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", intrinsicHeight: 300, intrinsicWidth: 510, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 1200, pixelWidth: 2040, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/XM3VVdgrkQPmRFq5yRmo38JCotY.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/XM3VVdgrkQPmRFq5yRmo38JCotY.png?scale-down-to=512 512w,https://framerusercontent.com/images/XM3VVdgrkQPmRFq5yRmo38JCotY.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/XM3VVdgrkQPmRFq5yRmo38JCotY.png 2040w"}} className={cx(scopingClassNames, "framer-tdo1hm", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Nu0FrfrIq"} ref={refBinding} style={{...style}} {...addPropertyOverrides({AzpNTi8mb: {"data-framer-name": "Variant 4"}, dEOTIs8oZ: {"data-framer-name": "Variant 5"}, gwwCWmGR_: {"data-framer-name": "Variant 6"}, owcr8bAQ2: {"data-framer-name": "Variant 2"}, rNYhabyFZ: {"data-framer-name": "Variant 3"}}, baseVariant, gestureVariant)}/></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-v45y8.framer-1uqm8cf, .framer-v45y8 .framer-1uqm8cf { display: block; }", ".framer-v45y8.framer-tdo1hm { height: 659px; overflow: visible; position: relative; width: 1120px; }", ".framer-v45y8.framer-v-12wfykl.framer-tdo1hm, .framer-v45y8.framer-v-bgfawe.framer-tdo1hm, .framer-v45y8.framer-v-1bxr2i4.framer-tdo1hm, .framer-v45y8.framer-v-1wzycri.framer-tdo1hm, .framer-v45y8.framer-v-v9fcle.framer-tdo1hm { aspect-ratio: 1.7 / 1; height: var(--framer-aspect-ratio-supported, 659px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 659
 * @framerIntrinsicWidth 1120
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"owcr8bAQ2":{"layout":["fixed","fixed"]},"rNYhabyFZ":{"layout":["fixed","fixed"]},"AzpNTi8mb":{"layout":["fixed","fixed"]},"dEOTIs8oZ":{"layout":["fixed","fixed"]},"gwwCWmGR_":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerHjH5KkMsQ: React.ComponentType<Props> = withCSS(Component, css, "framer-v45y8") as typeof Component;
export default FramerHjH5KkMsQ;

FramerHjH5KkMsQ.displayName = "img-careers";

FramerHjH5KkMsQ.defaultProps = {height: 659, width: 1120};

addPropertyControls(FramerHjH5KkMsQ, {variant: {options: ["Nu0FrfrIq", "owcr8bAQ2", "rNYhabyFZ", "AzpNTi8mb", "dEOTIs8oZ", "gwwCWmGR_"], optionTitles: ["Variant 1", "Variant 2", "Variant 3", "Variant 4", "Variant 5", "Variant 6"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerHjH5KkMsQ, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})